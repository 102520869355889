import type { UIMatch } from '@remix-run/react'
import { Outlet, useMatches, useRouteLoaderData } from '@remix-run/react'

import { GenericErrorSection } from '~/components/boundaries/GenericErrorBoundary'
import Layout from '~/components/Layout'
import { useRouteErrorWithReporter } from '~/hooks/useRouteErrorWithReporter'
import type { loader as landingPageLoader } from '~/routes/__site/$slug/index'
import type { loader as homePageLoader } from '~/routes/__site/index/index'

const SitePage = () => {
  const matches = useMatches() as UIMatch<{}, { isHeaderHidden?: boolean }>[]

  const landingPageData = useRouteLoaderData<typeof landingPageLoader>(
    'routes/__site/$slug/index',
  )
  const homePageData = useRouteLoaderData<typeof homePageLoader>(
    'routes/__site/index/index',
  )

  const callToActionURL =
    homePageData?.ctaButtonLink ?? landingPageData?.page?.data?.flow

  const isHeaderHidden = !!matches.find(match => match.handle?.isHeaderHidden)
    ?.handle?.isHeaderHidden

  const isFooterHidden = landingPageData?.page?.data?.showFooter === false

  return (
    <Layout
      isHeaderHidden={isHeaderHidden}
      isFooterHidden={isFooterHidden}
      headerCTA={callToActionURL}
      footerCTA={callToActionURL}
    >
      <Outlet />
    </Layout>
  )
}

export function ErrorBoundary() {
  const error = useRouteErrorWithReporter()

  if (process.env.NODE_ENV !== 'production') console.error(error)

  return <GenericErrorSection />
}

export default SitePage
